import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExpertFile } from '@techspert-io/expert-files';
import {
  Subject,
  combineLatest,
  filter,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { AssistantFilesService } from '../../../services/assistantFiles.service';
import { AssistantQuotesService } from '../../../services/savedQuote.service';
import { TranscriptChunkingService } from '../../../services/transcriptChunking.service';

interface ITranscriptChunk {
  start: string;
  end: string;
  startNum: number;
  endNum: number;
  speaker: string;
  content: string;
  highlighted: boolean;
  savedQuoteId?: string;
  loading?: boolean;
}

@Component({
  selector: 'app-assistant-playback',
  templateUrl: './assistant-playback.component.html',
  styleUrls: ['./assistant-playback.component.scss'],
})
export class AssistantPlaybackComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  audioStartTime = 0;
  transcriptFile: ExpertFile;
  audioData: string;
  loadingFiles: boolean;
  autoScroll = true;
  transcriptChunks: ITranscriptChunk[] = [];

  constructor(
    private assistantFilesService: AssistantFilesService,
    private assistantQuotesService: AssistantQuotesService,
    private transcriptChunkingService: TranscriptChunkingService
  ) {}

  ngOnInit() {
    this.assistantFilesService.selectedfile$
      .pipe(
        tap((file) => {
          if (!file) {
            this.transcriptFile = null;
            this.audioData = null;
          }
        }),
        filter((file) => !!file),
        tap(() => {
          this.loadingFiles = true;
        }),
        switchMap(({ transcriptFile, audioFile, closestTimestamp }) =>
          combineLatest([
            this.assistantFilesService.getAssistantTranscriptFileContents(
              transcriptFile
            ),
            audioFile
              ? this.assistantFilesService.getAssistantAudioFileContents(
                  audioFile
                )
              : of(undefined),
            this.assistantQuotesService.getQuotesByTranscriptFileId(
              transcriptFile.fileId
            ),
          ]).pipe(
            tap(([content, audio, quotes]) => {
              this.transcriptChunks =
                this.transcriptChunkingService.createChunks(content, quotes);

              this.transcriptFile = transcriptFile;

              if (audio) {
                this.audioData = URL.createObjectURL(audio);
              }

              this.audioStartTime = closestTimestamp
                ? this.audioTime(closestTimestamp)
                : 0;

              this.updateAndScroll(this.audioStartTime);

              this.loadingFiles = false;
            })
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateTime(event: Event) {
    const target = event.target as HTMLAudioElement;

    this.updateAndScroll(target.currentTime);
  }

  updateQuote(chunk: ITranscriptChunk) {
    if (chunk.loading) {
      return;
    }

    chunk.loading = true;

    if (chunk.savedQuoteId) {
      this.assistantQuotesService
        .deleteQuote(chunk.savedQuoteId)
        .subscribe(() => {
          chunk.savedQuoteId = null;
          chunk.loading = false;
        });
    } else {
      this.assistantQuotesService
        .saveQuote({
          transcriptFileId: this.transcriptFile.fileId,
          expertId: this.transcriptFile.expertId,
          startTime: chunk.startNum,
          endTime: chunk.endNum,
          quote: chunk.content,
        })
        .subscribe((quote) => {
          chunk.savedQuoteId = quote.savedQuoteId;
          chunk.loading = false;
        });
    }
  }

  private updateAndScroll(time: number) {
    const currentHighlightedPosition = this.transcriptChunks.findIndex(
      (chunk) => chunk.highlighted
    );

    const newHighlightedPosition = this.transcriptChunks.findIndex(
      (chunk) => chunk.startNum <= time && chunk.endNum >= time
    );

    if (currentHighlightedPosition !== newHighlightedPosition) {
      this.transcriptChunks = this.transcriptChunks.map((chunk) => ({
        ...chunk,
        highlighted: chunk.startNum <= time && chunk.endNum >= time,
      }));
    }

    if (this.autoScroll) {
      setTimeout(() => {
        const element = document.querySelector('.highlight');
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'start',
          });
        }
      });
    }

    if (!time) {
      const element = document.querySelector('.transcript-content');
      if (element) {
        element.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  }

  private audioTime(time: string) {
    const [hours, minutes, seconds] = time.split(':').map(Number);

    return hours * 3600 + minutes * 60 + seconds;
  }
}
