<div class="flex justify-between content-center">
  <h3 mat-dialog-title>
    Editing sheet
    <input class="input" matInput type="text" [placeholder]="data.name" [formControl]="nameControl">
  </h3>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>clear</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <app-loader *ngIf="loading"></app-loader>

  <form [formGroup]="editFormGroup" class="flex flex-row gap-8" *ngIf="editFormGroup && !loading">
    <div class="flex flex-col basis-[50%] gap-4">
      <app-reorderable-expansion-panels></app-reorderable-expansion-panels>

      <ng-container *ngIf="editFormSubmitted">
        <mat-error *ngIf="themesControl.hasError('noThemes')">
          At least one section and question must be added
        </mat-error>

        <mat-error *ngIf="themesControl.hasError('noThemeName')">
          Interview section name is required
        </mat-error>

        <mat-error *ngIf="themesControl.hasError('noQuestions')">
          At least one question is required for every interview section
        </mat-error>

        <mat-error *ngIf="themesControl.hasError('noQuestionName')">
          Question name is required for all present questions
        </mat-error>

        <mat-error *ngIf="themesControl.hasError('duplicateThemeNames')">
          Interview section names must be unique
        </mat-error>

        <mat-error *ngIf="themesControl.hasError('duplicateQuestionNames')">
          Question names must be unique within each interview section
        </mat-error>
      </ng-container>
    </div>

    <div class="flex flex-col basis-[50%] gap-4">
      <mat-form-field>
        <mat-label>Which segment(s) should be linked to this sheet?</mat-label>
        <mat-select multiple formControlName="segments">
          <mat-option *ngFor="let segment of segments" [value]="segment.segmentId"
            [disabled]="segment.occupied && !data.opportunity_segment_ids.includes(segment.segmentId)">
            {{ segment.segmentName }}
          </mat-option>
        </mat-select>
        <mat-error>At least one segment must be selected</mat-error>
      </mat-form-field>

      <div class="additional-fields-container flex flex-row gap-8">
        <div class="flex flex-col gap-1">
          <b>Optional fields</b>
          <mat-checkbox formControlName="geography">Geography</mat-checkbox>
          <mat-checkbox formControlName="profileType">Profile type</mat-checkbox>
          <mat-checkbox formControlName="dateAndTimeOfCall">Date and time of call</mat-checkbox>
          <mat-checkbox formControlName="interviewerName">
            <div class="flex flex-row items-center content-center gap-1">
              <span>Interviewer name</span>
              <mat-icon class="info" matTooltip="Free text field">info_outline</mat-icon>
            </div>
          </mat-checkbox>
          <mat-checkbox formControlName="notetakerName">
            <div class="flex flex-row items-center content-center gap-1">
              <span>Notetaker name</span>
              <mat-icon class="info" matTooltip="Free text field">info_outline</mat-icon>
            </div>
          </mat-checkbox>
          <mat-checkbox formControlName="recruitmentSource">
            <div class="flex flex-row items-center content-center gap-1">
              <span>Recruitment source</span>
              <mat-icon class="info" matTooltip="Displays the source for respondents added through file upload">
                info_outline
              </mat-icon>
            </div>
          </mat-checkbox>
        </div>

        <div class="flex flex-col gap-1">
          <b>Include in export</b>
          <mat-checkbox formControlName="expertBio">Expert bio</mat-checkbox>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="flex gap-2">
  <button class="flex-1 primaryColor-border" mat-stroked-button mat-dialog-close>
    Close window
  </button>
  <button class="flex-1" mat-flat-button color="primary" (click)="confirmChange()">
    Confirm
  </button>
</mat-dialog-actions>