import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDataCaptureSegment } from '../../models/data-capture.models';
import { DataCaptureService } from '../../services/data-capture.service';

@Component({
  selector: 'app-add-respondent',
  templateUrl: './add-respondent.component.html',
  styleUrls: ['./add-respondent.component.scss'],
})
export class AddRespondentComponent implements OnInit {
  addRespondentFormGroup = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    geography: new FormControl('', Validators.required),
    segment: new FormControl('', Validators.required),
    dateOfCall: new FormControl('', Validators.required),
    source: new FormControl('', Validators.required),
    compliance: new FormControl(false, Validators.requiredTrue),
  });

  segments: IDataCaptureSegment[] = [];

  loading = true;

  get complianceControl() {
    return this.addRespondentFormGroup.controls.compliance;
  }

  constructor(
    private dataCaptureService: DataCaptureService,
    private dialogRef: MatDialogRef<AddRespondentComponent>,
    @Inject(MAT_DIALOG_DATA) private opportunityId: string
  ) {}

  ngOnInit() {
    this.dataCaptureService
      .getAvailableSegments(this.opportunityId)
      .subscribe((res) => {
        this.segments = res;
        this.loading = false;
      });
  }

  submit() {
    if (!this.addRespondentFormGroup.valid) {
      this.addRespondentFormGroup.markAllAsTouched();
      return;
    }

    this.dialogRef.close(this.addRespondentFormGroup.value);
  }
}
