import { IRespondent, RespondentSource } from '../models/data-capture.models';

export const respondentsMock: IRespondent[] = [
  {
    respondentId: '1',
    firstName: 'John',
    lastName: 'Doe',
    geography: 'US',
    segment: 'Nurse',
    opportunitySegmentId: '1',
    callDate: '2025-01-13T00:00:00.000Z',
    bio: '',
    source: RespondentSource.Techspert,
    transcribing: false,
    answers: {},
    files: [],
  },
  {
    respondentId: '2',
    firstName: 'Jane',
    lastName: 'Doe',
    geography: 'US',
    segment: 'Nurse',
    opportunitySegmentId: '1',
    callDate: '2025-01-13T00:00:00.000Z',
    bio: '',
    source: RespondentSource.Techspert,
    transcribing: false,
    answers: {},
    files: [],
  },
];
