import { Injectable } from '@angular/core';
import { ISavedQuote } from '../models/savedQuotes.models';

@Injectable()
export class TranscriptChunkingService {
  createChunks(content: string, savedQuotes: ISavedQuote[]) {
    const matches = content.matchAll(
      /(\d+)\r\n(\d{2}:\d{2}:\d{2}\.\d{3}) --> (\d{2}:\d{2}:\d{2}\.\d{3})\r\n(.+):(.+)[\n\r]+/g
    );

    return [...new Set(matches || [])].map(
      ([, , start, end, speaker, content]) => {
        const startNum = this.audioTime(start);
        const endNum = this.audioTime(end);
        const savedQuote = savedQuotes.find(
          (quote) => quote.startTime === startNum && quote.endTime === endNum
        );

        return {
          start,
          end,
          startNum,
          endNum,
          speaker: speaker.trim(),
          content: content.trim(),
          highlighted: false,
          savedQuoteId: savedQuote ? savedQuote.savedQuoteId : null,
        };
      }
    );
  }

  private audioTime(time: string) {
    const [hours, minutes, seconds] = time.split(':').map(Number);

    return Number((hours * 3600 + minutes * 60 + seconds).toFixed(3));
  }
}
