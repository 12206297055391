import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FileModule } from '@techspert-io/files';
import { ExpertEchoComponent } from '../../public/opportunity/expert-list/expert/expert-echo/expert-echo.component';
import { ExpertFilesComponent } from '../../public/opportunity/expert-list/expert/expert-files/expert-files.component';
import { ExpertQuotesComponent } from '../../public/opportunity/expert-list/expert/expert-quotes/expert-quotes.component';
import { ExpertEchoMarkdownPipe } from '../../public/opportunity/expert-list/pipes/expert-echo-markdown.pipe';
import { QuoteTimestampPipe } from '../../public/opportunity/expert-list/pipes/quote-timestamp.pipe';
import { SharedModule } from '../../shared/shared.module';
import { TranscriptChunkingService } from '../insight-assistant/services/transcriptChunking.service';
import { AddRespondentComponent } from './components/add-respondent/add-respondent.component';
import { DataCaptureComponent } from './components/data-capture/data-capture.component';
import { DataSheetComponent } from './components/data-sheet/data-sheet.component';
import { DeleteSheetConfirmationComponent } from './components/delete-sheet-confirmation/delete-sheet-confirmation.component';
import { EditSheetComponent } from './components/edit-sheet/edit-sheet.component';
import { ReorderableExpansionPanelsComponent } from './components/re-orderable-theme/re-orderable-theme.component';
import { SheetGetStartedComponent } from './components/sheet-get-started/sheet-get-started.component';
import { SkeletonDataCaptureComponent } from './components/skeleton-data-capture/skeleton-data-capture.component';
import { ViewAnswerComponent } from './components/view-answer/view-answer.component';
import { ViewRespondentComponent } from './components/view-respondent/view-respondent.component';
import { RespondentStatusPipe } from './pipes/respondent-status.pipe';
import { TimestampPipe } from './pipes/timestamp.pipe';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    FileModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatStepperModule,
    MatCheckboxModule,
    SharedModule,
    MatExpansionModule,
    DragDropModule,
    MatTooltipModule,
  ],
  declarations: [
    DataCaptureComponent,
    SheetGetStartedComponent,
    AddRespondentComponent,
    EditSheetComponent,
    ViewRespondentComponent,
    DeleteSheetConfirmationComponent,
    DataSheetComponent,
    ReorderableExpansionPanelsComponent,
    SkeletonDataCaptureComponent,
    ViewAnswerComponent,
    RespondentStatusPipe,
    TimestampPipe,
    ExpertQuotesComponent,
    ExpertEchoComponent,
    ExpertFilesComponent,
    ExpertEchoMarkdownPipe,
    QuoteTimestampPipe,
  ],
  exports: [
    DataCaptureComponent,
    ExpertQuotesComponent,
    ExpertEchoComponent,
    ExpertFilesComponent,
    ExpertEchoMarkdownPipe,
    QuoteTimestampPipe,
  ],
  providers: [TranscriptChunkingService],
})
export class DataCaptureModule {}
