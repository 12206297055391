<div class="flex flex-col gap-4">
  <form [formGroup]="formGroup" class="flex flex-col gap-4">
    <div cdkDropList formArrayName="themes" (cdkDropListDropped)="onThemeDrop($event)" class="theme-list">
      <div *ngFor="let themeGroup of themes.controls; let i = index" [formGroupName]="i" class="flex flex-col" cdkDrag>
        <div class="theme flex flex-row justify-between basis-[100%]">
          <div class="flex flex-row gap-2 flex-grow">
            <mat-icon class="drag">drag_indicator</mat-icon>
            <mat-icon class="toggle-collapse" (click)="toggleCollapse(i)">
              {{ collapsedStates[i] ? 'expand_more' : 'expand_less' }}
            </mat-icon>
            <input formControlName="theme" placeholder="Interview section name..." matInput />
          </div>
          <mat-icon class="delete" (click)="removeTheme(i)">
            close
          </mat-icon>
        </div>

        <div class="flex flex-col" *ngIf="!collapsedStates[i]">
          <div cdkDropList [cdkDropListData]="themeGroup.controls.questions.value" formArrayName="questions"
            (cdkDropListDropped)="onQuestionDrop(i, $event)" class="question-list">
            <div *ngFor="let questionGroup of themeGroup.controls.questions.controls; let j = index" [formGroupName]="j"
              class="question flex flex-row justify-between gap-2" cdkDrag>
              <div class="flex flex-row gap-2 flex-grow items-center">
                <mat-icon class="drag">drag_indicator</mat-icon>
                <mat-icon class="check">check_circle</mat-icon>
                <input formControlName="question" placeholder="Question..." matInput />
              </div>
              <mat-icon class="delete" (click)="removeQuestion(i, j)">
                close
              </mat-icon>
            </div>
            <div class="question add-question flex flex-row items-center" (click)="addQuestion(i)">
              <span>Add question...</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button mat-button color="primary" (click)="addTheme()" class="add-theme">
      Add interview section
    </button>
  </form>

  <p *ngIf="helperText">
    Once your sheet is published, you’ll be able to add and delete new and existing questions. If you
    need to edit an existing question once published, you will need to delete and re-add it.
  </p>
</div>