import { ExpertFile } from '../../expert-files/models/expert-file.model';

export interface IDiscussionGuideQuestion {
  question_id: string;
  question: string;
  theme: string;
  label: string;
}

export interface IDataCaptureSheetBase {
  data_capture_sheet_id: string;

  name: string;

  opportunity_id: string; // Index

  opportunity_segment_ids: string[];

  discussion_guide: IDiscussionGuideQuestion[];

  additional_export_headers: {
    expert_name: boolean;
    expert_bio: boolean;

    geography: boolean;
    profile_type: boolean;

    date_and_time_of_call: boolean;

    /* Not required for v1 */
    interviewer_name: boolean;

    /* Not required for v1 */
    notetaker_name: boolean;
  };
}

export interface IDataCaptureSheetAccessLogs {
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
}

export enum RespondentStatus {
  ProcessingRecording = 'processingRecording',
  Transcription = 'transcription',
  GeneratingResponse = 'generatingResponse',
  Complete = 'complete',
}

export interface IDataCaptureSheet
  extends IDataCaptureSheetBase,
    IDataCaptureSheetAccessLogs {}

export interface IRespondentAnswerReference {
  citation: string;
  timestampStart: number;
  timestampEnd: number;
}

export interface IRespondentAnswerBase {
  respondent_answer_id: string;

  /* ExpertId or external respondent id */
  respondent_id: string;

  question_id: string;

  answer: string;

  /* Could be a transcript chunk ID in the future */
  citation: IRespondentAnswerReference[];
}

export interface IRespondentAnswerAccessLogs {
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
}

export interface IRespondentAnswer
  extends IRespondentAnswerBase,
    IRespondentAnswerAccessLogs {}

export enum RespondentSource {
  Techspert = 'techspert',
  Other = 'other',
}

export interface IRespondent {
  /* ExpertId or external respondent id */
  respondentId: string;

  firstName: string;
  lastName: string;

  geography: string;
  segment: string;
  opportunitySegmentId: string;

  /* ISO format */
  callDate: string;

  bio: string;

  source: RespondentSource;

  /* May be worth filtering this out */
  transcribing: boolean;

  /* May need a failed flag here also */

  answers: Record<string, IRespondentAnswer>;

  files: ExpertFile[];

  echoSummary?: ExpertFile;
}

export interface IDataCaptureSegment {
  segmentId: string;
  segmentName: string;
  occupied: boolean;
}
