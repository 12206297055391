import { ISavedQuote, ISavedQuoteBase } from '../models/savedQuotes.models';

export const baseSavedQuoteMock: ISavedQuoteBase = {
  transcriptFileId: 'transcriptFileId',
  startTime: 0,
  endTime: 0,
  quote: 'quote',
  expertId: 'expertId',
};

export const savedQuoteMock: ISavedQuote = {
  ...baseSavedQuoteMock,
  savedQuoteId: 'savedQuoteId',
  userId: 'userId',

  dateCreated: 0,
  createdBy: 'createdBy',
  lastUpdated: 0,
  lastUpdatedBy: 'updatedBy',
};
