import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExpertFile } from '@techspert-io/expert-files';
import {
  AssistantFilesService,
  AssistantQuotesService,
  TranscriptChunkingService,
} from '@techspert-io/insight-assistant';
import { combineLatest, of } from 'rxjs';
import {
  IDiscussionGuideQuestion,
  IRespondent,
  IRespondentAnswerReference,
} from '../../models/data-capture.models';

export interface IRespodentDialogData {
  respondentId: string;
  respondents: IRespondent[];
  groupedThemes: {
    theme: string;
    questions: IDiscussionGuideQuestion[];
  }[];
}

interface ITranscriptChunk {
  start: string;
  end: string;
  startNum: number;
  endNum: number;
  speaker: string;
  content: string;
  highlighted: boolean;
  savedQuoteId?: string;
  loading?: boolean;
}

@Component({
  selector: 'app-view-respondent',
  templateUrl: './view-respondent.component.html',
  styleUrls: ['./view-respondent.component.scss'],
})
export class ViewRespondentComponent implements OnInit {
  loading = true;

  selectedRespondent: IRespondent;

  groupedThemes: {
    theme: string;
    questions: (IDiscussionGuideQuestion & {
      mappedQuestionId: string;
    })[];
  }[];

  audioStartTime = 0;
  transcriptFile: ExpertFile;
  audioData: string;
  autoScroll = true;
  transcriptChunks: ITranscriptChunk[] = [];

  constructor(
    private assistantFilesService: AssistantFilesService,
    private assistantQuotesService: AssistantQuotesService,
    private transcriptChunkingService: TranscriptChunkingService,
    private dialogRef: MatDialogRef<ViewRespondentComponent>,
    @Inject(MAT_DIALOG_DATA) private data: IRespodentDialogData
  ) {}

  ngOnInit() {
    this.loadResources(this.data.respondentId);
    this.groupedThemes = this.data.groupedThemes.map((group) => ({
      ...group,
      questions: group.questions.map((question) => ({
        ...question,
        mappedQuestionId: question.question_id.replace(/-/g, ''),
      })),
    }));
  }

  navigateBack() {
    const currentIndex = this.data.respondents.findIndex(
      (r) => r.respondentId === this.selectedRespondent.respondentId
    );

    const previousIndex =
      currentIndex === 0 ? this.data.respondents.length - 1 : currentIndex - 1;

    this.loadResources(this.data.respondents[previousIndex].respondentId);
  }

  navigateForward() {
    const currentIndex = this.data.respondents.findIndex(
      (r) => r.respondentId === this.selectedRespondent.respondentId
    );

    const nextIndex =
      currentIndex === this.data.respondents.length - 1 ? 0 : currentIndex + 1;

    this.loadResources(this.data.respondents[nextIndex].respondentId);
  }

  navigateCitation(citation: IRespondentAnswerReference) {
    this.audioStartTime = citation.timestampStart
      ? citation.timestampStart / 1000
      : 0;

    this.updateAndScroll(this.audioStartTime);
  }

  updateTime(event: Event) {
    const target = event.target as HTMLAudioElement;

    this.updateAndScroll(target.currentTime);
  }

  updateQuote(chunk: ITranscriptChunk) {
    if (chunk.loading) {
      return;
    }

    chunk.loading = true;

    if (chunk.savedQuoteId) {
      this.assistantQuotesService
        .deleteQuote(chunk.savedQuoteId)
        .subscribe(() => {
          chunk.savedQuoteId = null;
          chunk.loading = false;
        });
    } else {
      this.assistantQuotesService
        .saveQuote({
          transcriptFileId: this.transcriptFile.fileId,
          expertId: this.transcriptFile.expertId,
          startTime: chunk.startNum,
          endTime: chunk.endNum,
          quote: chunk.content,
        })
        .subscribe((quote) => {
          chunk.savedQuoteId = quote.savedQuoteId;
          chunk.loading = false;
        });
    }
  }

  confirm() {
    // get all the quotes that are saved
    this.dialogRef.close();
  }

  private loadResources(respondentId: string) {
    this.loading = true;

    const respondent = this.data.respondents.find(
      (r) => r.respondentId === respondentId
    );
    this.selectedRespondent = { ...respondent };

    const transcriptFile = this.selectedRespondent.files.find(
      (file) =>
        file.type === 'enhancedTranscript' && file.fileExtension === 'vtt'
    );

    const audioFile = this.selectedRespondent.files.find(
      (file) => file.type === 'zoomRecording' && file.fileExtension === 'm4a'
    );

    combineLatest([
      this.assistantFilesService.getAssistantTranscriptFileContents(
        transcriptFile
      ),
      audioFile
        ? this.assistantFilesService.getAssistantAudioFileContents(audioFile)
        : of(undefined),
      this.assistantQuotesService.getQuotesByTranscriptFileId(
        transcriptFile.fileId
      ),
    ]).subscribe(([content, audio, quotes]) => {
      this.transcriptChunks = this.transcriptChunkingService.createChunks(
        content,
        quotes
      );

      this.transcriptFile = transcriptFile;

      if (audio) {
        this.audioData = URL.createObjectURL(audio);
      }

      this.audioStartTime = 0;

      this.updateAndScroll(this.audioStartTime);

      this.loading = false;
    });
  }

  private updateAndScroll(time: number) {
    const currentHighlightedPosition = this.transcriptChunks.findIndex(
      (chunk) => chunk.highlighted
    );

    const newHighlightedPosition = this.transcriptChunks.findIndex(
      (chunk) => chunk.startNum <= time && chunk.endNum >= time
    );

    if (currentHighlightedPosition !== newHighlightedPosition) {
      this.transcriptChunks = this.transcriptChunks.map((chunk) => ({
        ...chunk,
        highlighted: chunk.startNum <= time && chunk.endNum >= time,
      }));
    }

    if (this.autoScroll) {
      setTimeout(() => {
        const element = document.querySelector('.highlight');
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'start',
          });
        }
      });
    }

    if (!time) {
      const element = document.querySelector('.transcript-content');
      if (element) {
        element.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  }
}
