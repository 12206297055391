import {
  IOpportunity,
  IOpportunitySegment,
  IOpportunitySegmentBaseStats,
  IOpportunitySegmentStats,
} from '../models/opportunity.interface';

export const mockBaseOpportunitySegmentStats: IOpportunitySegmentStats[] = [
  {
    opportunitySegmentId: 'sfId1',
    geography: 'UK',
    segment: 'Nurse',
    target: 10,
    screening: 3,
    inPortal: 0,
    scheduled: 0,
    completed: 0,
    confirmed: 0,
  },
  {
    opportunitySegmentId: 'sfId2',
    geography: 'US',
    segment: 'Doctor',
    target: 10,
    screening: 3,
    inPortal: 3,
    scheduled: 6,
    completed: 3,
    confirmed: 9,
  },
];

export const mockBaseOpportunitySegmentStatsTotal: IOpportunitySegmentBaseStats =
  {
    target: 20,
    screening: 6,
    inPortal: 3,
    scheduled: 6,
    completed: 3,
    confirmed: 9,
  };

export const mockBaseOpportunitySegment: IOpportunitySegment = {
  opportunitySegmentId: 'opportunitySegmentId',
  geography: 'UK',
  segment: 'Nurse',
  amount: 10,
};

export const baseOpportunitySegments: IOpportunitySegment[] = [
  {
    ...mockBaseOpportunitySegment,
    opportunitySegmentId: 'sfId1',
    geography: 'UK',
    segment: 'Nurse',
    amount: 10,
  },
  {
    ...mockBaseOpportunitySegment,
    opportunitySegmentId: 'sfId2',
    geography: 'US',
    segment: 'Doctor',
    amount: 10,
  },
  {
    ...mockBaseOpportunitySegment,
    opportunitySegmentId: 'sfId3',
    geography: 'geographicTarget',
    segment: 'profileType',
    amount: 10,
  },
];

export const baseOpportunityMock: IOpportunity = {
  clientId: 'clientId',
  opportunityId: 'oppId',
  opportunityName: 'testOpp',
  closeDate: '2021-08-14',
  opportunityOwner: 'owner',
  countries: ['UK'],
  publicDisplayId: 'publicDisplayId',
  callRecording: false,
  blind: true,
  screenshare: false,
  dataCapture: false,
  callTranscripts: false,
  cepLog: false,
  expertTargetQuantity: 12,
  startDate: '2021-07-29',
  conferenceReminderDefaultSettings: {
    enabled: false,
    sendEmailBeforeSeconds: 21600,
  },
  stageName: 'Project in progress',
  defaultConferenceTopic: 'Opp Conf Topic',
  anticipatedCallTime: 45,
  screenerEnabled: false,
  closedEmailSend: true,
  automatedScheduling: false,
  failedScreenerEmailSend: false,
  omnisearchHidden: false,
  omnisearchExpertNotification: true,
  endDate: '2021-07-21',
  lastUpdated: '12345678',
  salesforceId: 'sfId',
  clientContactIds: [],
  opportunityAssigneeIds: [],
  status: 'open',
  onHold: false,
  slackChannels: [],
  files: [],
  opportunitySegmentStats: mockBaseOpportunitySegmentStats,
  opportunitySegmentStatsTotal: mockBaseOpportunitySegmentStatsTotal,
  opportunitySegments: baseOpportunitySegments,
  createExpertTranscriptSummary: false,
  createSegmentTranscriptSummary: false,
};
