<div class="skeleton-container">
  <div class="table-container">
    <div class="table-row table-header">
      <div class="header-cell"></div>
      <div class="header-cell">Interview section</div>
      <div class="header-cell"></div>
    </div>

    <div class="table-row">
      <div class="header-cell">Respondent</div>
      <div class="header-cell">Question 1</div>
      <div class="skeleton-cell"></div>
    </div>

    <div *ngFor="let row of rows; index as i" class="table-row">
      <ng-container *ngIf="!segments[i]">
        <div class="skeleton-cell"></div>
        <div class="skeleton-cell"></div>
        <div class="skeleton-cell"></div>
      </ng-container>
      <ng-container *ngIf="segments[i]">
        <div class="respondent-cell flex flex-col">
          <span class="name">Jane Doe</span>
          <span>{{ segments[i].segmentName }}</span>
        </div>
        <div class="skeleton-cell"></div>
        <div class="skeleton-cell"></div>
      </ng-container>
    </div>
  </div>

  <div class="footer-list">
    <div class="footer-sheet">
      <span>{{ sheetName }}</span>
    </div>
    <div class="footer-sheet plus">
      <span>+</span>
    </div>
  </div>
</div>