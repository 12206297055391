<div class="flex justify-between content-center">
  <h3 mat-dialog-title>Add respondent</h3>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>clear</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <app-loader *ngIf="loading"></app-loader>

  <form [formGroup]="addRespondentFormGroup" class="flex flex-col gap-4" *ngIf="!loading">
    <div class="flex flex-row gap-4">
      <mat-form-field class="flex-1">
        <mat-label>First name</mat-label>
        <input matInput formControlName="firstName">
        <mat-error>Required</mat-error>
      </mat-form-field>

      <mat-form-field class="flex-1">
        <mat-label>Last name</mat-label>
        <input matInput formControlName="lastName">
        <mat-error>Required</mat-error>
      </mat-form-field>
    </div>

    <mat-form-field>
      <mat-label>Geography</mat-label>
      <input matInput formControlName="geography">
      <mat-error>Required</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Segment</mat-label>
      <mat-select formControlName="segment">
        <mat-option *ngFor="let segment of segments" [value]="segment.segmentId">
          {{ segment.segmentName }}
        </mat-option>
      </mat-select>
      <mat-error>Required</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Date of call</mat-label>
      <input matInput type="date" formControlName="dateOfCall">
      <mat-error>Required</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Source</mat-label>
      <input matInput formControlName="source">
      <mat-error>Required</mat-error>
    </mat-form-field>

    <div></div>

    <div></div>

    <mat-checkbox formControlName="compliance">
      <i [class.error]="complianceControl.invalid && complianceControl.touched">
        By uploading a third party file to the ECHO platform I am agreeing to the T&Cs of the Techspert Platform License
        Agreement
      </i>
    </mat-checkbox>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="flex gap-2">
  <button class="flex-1 primaryColor-border" mat-stroked-button mat-dialog-close>
    Close window
  </button>
  <button class="flex-1" mat-flat-button color="primary" (click)="submit()">
    Confirm
  </button>
</mat-dialog-actions>