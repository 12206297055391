import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DataCaptureModule } from '@techspert-io/data-capture';
import { ExpertsService } from '@techspert-io/experts';
import { FileModule } from '@techspert-io/files';
import { AssistantModule } from '@techspert-io/insight-assistant';
import { NotificationsModule } from '@techspert-io/notifications';
import { OmnisearchModule } from '@techspert-io/omnisearch';
import { PatternsModule } from '@techspert-io/patterns';
import { SharedExpertEmailsService } from '@techspert-io/shared-expert-emails';
import { SharedModule } from '../../shared/shared.module';
import { AuthDialogComponent } from './components/auth-dialog/auth-dialog.component';
import { ExpertSummaryDownloadDirective } from './directives/expert-export.directive';
import { ExpertListComponent } from './expert-list/expert-list.component';
import { ExpertTableComponent } from './expert-list/expert-table/expert-table.component';
import { AcceptanceWithdrawalBlockedDialogComponent } from './expert-list/expert/dialogs/acceptance-withdrawal-blocked-dialog/acceptance-withdrawal-blocked-dialog.component';
import { ExpertNoShowDialogComponent } from './expert-list/expert/dialogs/expert-no-show-dialog/expert-no-show-dialog.component';
import { OnHoldReasonDialogComponent } from './expert-list/expert/dialogs/reason-for-on-hold/on-hold-reason-dialog.component';
import { ReasonRejectionDialogComponent } from './expert-list/expert/dialogs/reason-for-rejection/rejection-reason-dialog.component';
import { RequestConferenceDialogComponent } from './expert-list/expert/dialogs/request-conference-dialog/request-conference-dialog.component';
import { RequestExpertEngagementComponent } from './expert-list/expert/dialogs/request-expert-engagement/request-expert-engagement.component';
import { RequestTimeChangeComponent } from './expert-list/expert/dialogs/request-time-change/request-time-change.component';
import { SelectAvailabilityDialogComponent } from './expert-list/expert/dialogs/select-availability-dialog/select-availability-dialog.component';
import { ShareExpertDialogComponent } from './expert-list/expert/dialogs/share-expert/share-expert-dialog.component';
import { ExpertExpandAllToggleComponent } from './expert-list/expert/expert-expand-all-toggle/expert-expand-all-toggle.component';
import { ExpertFilterComponent } from './expert-list/expert/expert-filter/expert-filter.component';
import { ExpertHexagonComponent } from './expert-list/expert/expert-hexagon/expert-hexagon.component';
import { ExpertScreenerBtnComponent } from './expert-list/expert/expert-screener-btn/expert-screener-btn.component';
import { ExpertTextComponent } from './expert-list/expert/expert-text/expert-text.component';
import { ExpertTimelineComponent } from './expert-list/expert/expert-timeline/expert-timeline.component';
import { ExpertUnitComponent } from './expert-list/expert/expert-unit/expert-unit.component';
import { ExpertComponent } from './expert-list/expert/expert.component';
import { OnHoldSvgComponent } from './expert-list/on-hold-svg/on-hold-svg.component';
import { ApprovalTooltipPipe } from './expert-list/pipes/approval-tooltip.pipe';
import { ExpertListValuePipe } from './expert-list/pipes/expert-list-value.pipe';
import { GeographicProgressComponent } from './geographic-progress/geographic-progress.component';
import { EmptyStateComplianceSvgComponent } from './images/empty-state-svg.component';
import { OpportunityComponent } from './opportunity.component';
import { RemoveIndentPipe } from './pipes/remove-indent.pipe';
import { WorksheetService } from './services/worksheet.service';

@NgModule({
  declarations: [
    OpportunityComponent,
    ExpertListComponent,
    ExpertComponent,
    RequestTimeChangeComponent,
    RequestExpertEngagementComponent,
    ExpertNoShowDialogComponent,
    SelectAvailabilityDialogComponent,
    GeographicProgressComponent,
    ReasonRejectionDialogComponent,
    OnHoldReasonDialogComponent,
    ShareExpertDialogComponent,
    AcceptanceWithdrawalBlockedDialogComponent,
    ExpertTextComponent,
    ExpertFilterComponent,
    ExpertListValuePipe,
    ApprovalTooltipPipe,
    RemoveIndentPipe,
    ExpertScreenerBtnComponent,
    ExpertHexagonComponent,
    ExpertExpandAllToggleComponent,
    ExpertSummaryDownloadDirective,
    ExpertTableComponent,
    OnHoldSvgComponent,
    ExpertUnitComponent,
    AuthDialogComponent,
    EmptyStateComplianceSvgComponent,
    ExpertTimelineComponent,
    RequestConferenceDialogComponent,
  ],
  providers: [WorksheetService, ExpertsService, SharedExpertEmailsService],
  imports: [
    SharedModule,
    MatDialogModule,
    PatternsModule,
    OmnisearchModule,
    FileModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    AssistantModule,
    NotificationsModule,
    DataCaptureModule,
  ],
  exports: [
    OpportunityComponent,
    ExpertListComponent,
    ExpertComponent,
    RequestTimeChangeComponent,
    RequestExpertEngagementComponent,
    ShareExpertDialogComponent,
    ExpertNoShowDialogComponent,
    SelectAvailabilityDialogComponent,
    GeographicProgressComponent,
    ReasonRejectionDialogComponent,
    OnHoldReasonDialogComponent,
    AcceptanceWithdrawalBlockedDialogComponent,
    ExpertTextComponent,
    DataCaptureModule,
  ],
})
export class OpportunityModule {}
