import {
  IDataCaptureSheet,
  IRespondent,
  IRespondentAnswer,
  RespondentSource,
} from '../models/data-capture.models';

export const baseMockDataCaptureSheet: IDataCaptureSheet = {
  data_capture_sheet_id: 'dataCaptureSheetId',
  name: 'name',
  opportunity_id: 'opportunityId',
  opportunity_segment_ids: [],
  discussion_guide: [
    {
      question_id: 'aa9fa4fe-1a19-4b1b-9180-8c3d75f928ed',
      question:
        'Could you please briefly describe your medical background, specializations, and primary clinical practice setting? [Probe: Tenure in current position, prior experience, practice setting; role the management of psoriasis; research or clinical trial involvement related to psoriasis]',
      label: 'Medical background',
      theme: 'Interviewee Background',
    },
    {
      question_id: 'e1c6d088-85ef-44b2-a7b5-880e72cadcc1',
      question:
        'Approximately, what proportion of your total practice does psoriasis represent?',
      label: 'Proportion of practice',
      theme: 'Interviewee Background',
    },
    {
      question_id: 'ba8dd262-ef64-45c0-95b1-2970ec76b349',
      question:
        'How many psoriasis patients do you manage per month on an ongoing basis?',
      label: 'Number of patients',
      theme: 'Interviewee Background',
    },
  ],
  additional_export_headers: {
    expert_name: true,
    expert_bio: false,
    date_and_time_of_call: true,
    geography: true,
    profile_type: true,
    interviewer_name: true,
    notetaker_name: true,
  },
  created_at: '0',
  created_by: 'createdBy',
  updated_at: '0',
  updated_by: 'lastUpdatedBy',
};

export const baseMockRespondentAnswer: IRespondentAnswer = {
  respondent_answer_id: 'respondentAnswerId',
  respondent_id: 'respondentId',
  question_id: 'questionId',
  answer: 'answer',
  citation: [
    {
      citation: 'citation',
      timestampStart: 0,
      timestampEnd: 0,
    },
  ],
  created_at: '0',
  created_by: 'createdBy',
  updated_at: '0',
  updated_by: 'lastUpdatedBy',
};

export const baseMockRespondent: IRespondent = {
  respondentId: 'respondentId',
  firstName: 'firstName',
  lastName: 'lastName',
  geography: 'geography',
  segment: 'segment',
  opportunitySegmentId: 'segmentId',
  callDate: '2025-01-01T00:00:00Z',
  bio: 'bio',

  transcribing: false,
  source: RespondentSource.Techspert,

  answers: {
    'aa9fa4fe-1a19-4b1b-9180-8c3d75f928ed': {
      ...baseMockRespondentAnswer,
      question_id: 'aa9fa4fe-1a19-4b1b-9180-8c3d75f928ed',
      answer:
        'Dermatologist for the past 10 years in South Florida, practicing in a solo specialist group practice with six offices in the area. The practice is primarily focused on patient treatment, combining surgical and medical dermatology. [00:01:39.160 --> 00:02:14.230]',
    },
    'e1c6d088-85ef-44b2-a7b5-880e72cadcc1': {
      ...baseMockRespondentAnswer,
      question_id: 'e1c6d088-85ef-44b2-a7b5-880e72cadcc1',
      answer: 'Around 50%, just after acne. [00:02:24.878 --> 00:02:36.360]',
    },
    'ba8dd262-ef64-45c0-95b1-2970ec76b349': {
      ...baseMockRespondentAnswer,
      question_id: 'ba8dd262-ef64-45c0-95b1-2970ec76b349',
      answer: 'Around 150 patients per month. [00:02:45.280 --> 00:02:50.989]',
    },
  },

  files: [],
};
