import { Component, Inject, OnInit } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  IDataCaptureSegment,
  IDataCaptureSheet,
} from '../../models/data-capture.models';
import { DataCaptureService } from '../../services/data-capture.service';
import {
  FormGroupQuestion,
  FormGroupTheme,
} from '../re-orderable-theme/re-orderable-theme.component';

interface IEditFormGroup {
  reorderablePanel: FormGroup<{
    themes: FormArray<FormGroupTheme>;
  }>;
  segments: FormControl<string[]>;
  expertName: FormControl<boolean>;
  expertBio: FormControl<boolean>;
  dateAndTimeOfCall: FormControl<boolean>;
  geography: FormControl<boolean>;
  profileType: FormControl<boolean>;
  interviewerName: FormControl<boolean>;
  notetakerName: FormControl<boolean>;
  recruitmentSource: FormControl<boolean>;
}

@Component({
  selector: 'app-edit-sheet',
  templateUrl: './edit-sheet.component.html',
  styleUrls: ['./edit-sheet.component.scss'],
})
export class EditSheetComponent implements OnInit {
  nameControl: FormControl;
  editFormGroup: FormGroup<IEditFormGroup>;

  segments: IDataCaptureSegment[] = [];

  loading = true;

  editFormSubmitted = false;

  get reorderablePanel() {
    return this.editFormGroup.controls.reorderablePanel;
  }

  get themesControl() {
    return this.reorderablePanel.controls.themes;
  }

  constructor(
    private dataCaptureService: DataCaptureService,
    private dialogRef: MatDialogRef<EditSheetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDataCaptureSheet
  ) {}

  ngOnInit() {
    this.dataCaptureService
      .getAvailableSegments(this.data.opportunity_id)
      .subscribe((res) => {
        this.segments = res;
        this.loading = false;
      });

    const themes = this.groupQuestionsByTheme(this.data);

    this.nameControl = new FormControl(this.data.name);

    this.editFormGroup = new FormGroup<IEditFormGroup>({
      reorderablePanel: new FormGroup({
        themes: new FormArray<FormGroupTheme>(
          themes.map(
            (theme) =>
              new FormGroup({
                theme: new FormControl<string>({
                  value: theme.theme,
                  disabled: true,
                }),
                questions: new FormArray<FormGroupQuestion>(
                  (theme.questions || []).map(
                    (q) =>
                      new FormGroup({
                        question_id: new FormControl<string>(q.question_id),
                        question: new FormControl<string>({
                          value: q.question,
                          disabled: true,
                        }),
                        theme: new FormControl<string>({
                          value: q.theme,
                          disabled: true,
                        }),
                        label: new FormControl<string>(q.label),
                      })
                  )
                ),
              })
          ),
          [Validators.required, Validators.min(1), this.validateThemes()]
        ),
      }),
      segments: new FormControl<string[]>(this.data.opportunity_segment_ids, [
        Validators.required,
        Validators.min(1),
      ]),
      expertName: new FormControl(
        this.data.additional_export_headers.expert_name
      ),
      expertBio: new FormControl(
        this.data.additional_export_headers.expert_bio
      ),
      dateAndTimeOfCall: new FormControl(
        this.data.additional_export_headers.date_and_time_of_call
      ),
      geography: new FormControl(this.data.additional_export_headers.geography),
      profileType: new FormControl(
        this.data.additional_export_headers.profile_type
      ),
      interviewerName: new FormControl(
        this.data.additional_export_headers.interviewer_name
      ),
      notetakerName: new FormControl(
        this.data.additional_export_headers.notetaker_name
      ),
      recruitmentSource: new FormControl(
        this.data.additional_export_headers.notetaker_name
      ),
    });
  }

  confirmChange() {
    this.editFormSubmitted = true;

    if (this.editFormGroup.invalid) {
      return;
    }

    const {
      reorderablePanel,
      segments,
      expertName,
      expertBio,
      dateAndTimeOfCall,
      geography,
      profileType,
      interviewerName,
      notetakerName,
    } = this.editFormGroup.getRawValue();

    this.dialogRef.close({
      ...this.data,
      name: this.nameControl.value,
      discussion_guide: reorderablePanel.themes.flatMap(
        (theme) => theme.questions
      ),
      opportunity_segment_ids: segments,
      additional_export_headers: {
        expert_name: expertName,
        expert_bio: expertBio,
        date_and_time_of_call: dateAndTimeOfCall,
        geography,
        profile_type: profileType,
        interviewer_name: interviewerName,
        notetaker_name: notetakerName,
      },
    });
  }

  private groupQuestionsByTheme(sheet: IDataCaptureSheet) {
    const themes = sheet.discussion_guide.map((question) => question.theme);

    return [...new Set(themes)].map((theme) => ({
      theme,
      questions: sheet.discussion_guide.filter(
        (question) => question.theme === theme
      ),
    }));
  }

  private validateThemes(): ValidatorFn {
    return (control: FormArray<FormGroupTheme>): ValidationErrors => {
      const themes = control.getRawValue();

      if (!themes.length) {
        return { noThemes: true };
      }

      if (themes.some((theme) => !theme.theme)) {
        return { noThemeName: true };
      }

      if (themes.some((theme) => !theme.questions.length)) {
        return { noQuestions: true };
      }

      if (themes.some((theme) => theme.questions.some((q) => !q.question))) {
        return { noQuestionName: true };
      }

      const themeNames = themes.map((theme) => theme.theme);
      const uniqueThemeNames = [...new Set(themeNames)];

      if (themeNames.length !== uniqueThemeNames.length) {
        return { duplicateThemeNames: true };
      }

      if (
        themes.some((theme) => {
          const questionNames = theme.questions.map((q) => q.question);
          const uniqueQuestionNames = [...new Set(questionNames)];

          return questionNames.length !== uniqueQuestionNames.length;
        })
      ) {
        return { duplicateQuestionNames: true };
      }

      return null;
    };
  }
}
