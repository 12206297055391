import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IDataCaptureSegment,
  IDataCaptureSheet,
  IDataCaptureSheetBase,
  IRespondent,
  IRespondentAnswer,
} from '../models/data-capture.models';

@Injectable({
  providedIn: 'root',
})
export class DataCaptureService {
  private readonly baseUrl = '/insight-extraction';

  constructor(private http: HttpClient) {}

  querySheetsByOpportunityId(opportunityId: string) {
    return this.http.get<IDataCaptureSheet[]>(
      `${this.baseUrl}/data-capture-sheet?opportunityId=${opportunityId}`
    );
  }

  createSheet(payload: Omit<IDataCaptureSheetBase, 'data_capture_sheet_id'>) {
    return this.http.post<IDataCaptureSheet>(
      `${this.baseUrl}/data-capture-sheet`,
      payload
    );
  }

  editSheet(
    payload: Partial<IDataCaptureSheet> & { data_capture_sheet_id: string }
  ) {
    return this.http.patch<IDataCaptureSheet>(
      `${this.baseUrl}/data-capture-sheet/${payload.data_capture_sheet_id}`,
      payload
    );
  }

  deleteSheet(dataCaptureId: string) {
    return this.http.delete<undefined>(
      `${this.baseUrl}/data-capture-sheet/${dataCaptureId}`
    );
  }

  getRespodentsByDataCaptureSheetId(dataCaptureSheetId: string) {
    return this.http.get<IRespondent[]>(
      `${this.baseUrl}/data-capture-sheet/respondents?dataCaptureSheetId=${dataCaptureSheetId}`
    );
  }

  getAvailableSegments(opportunityId: string) {
    return this.http.get<IDataCaptureSegment[]>(
      `${this.baseUrl}/available-segments?opportunityId=${opportunityId}`
    );
  }

  updateRespondentAnswer(
    respondentAnswer: Partial<
      IRespondentAnswer & { respondent_answer_id: string }
    >
  ) {
    return this.http.patch<IRespondentAnswer>(
      `${this.baseUrl}/respondent-answer/${respondentAnswer.respondent_answer_id}`,
      respondentAnswer
    );
  }
}
