import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';

export type FormGroupQuestion = FormGroup<{
  question_id: FormControl<string>;
  question: FormControl<string>;
  theme: FormControl<string>;
  label: FormControl<string>;
}>;

export type FormGroupTheme = FormGroup<{
  theme: FormControl<string>;
  questions: FormArray<FormGroupQuestion>;
}>;

export type FormOverall = FormGroup<{
  themes: FormArray<FormGroupTheme>;
}>;

@Component({
  selector: 'app-reorderable-expansion-panels',
  templateUrl: './re-orderable-theme.component.html',
  styleUrls: ['./re-orderable-theme.component.scss'],
})
export class ReorderableExpansionPanelsComponent implements OnInit {
  @Input() helperText = false;

  formGroup: FormOverall;
  collapsedStates: boolean[] = [];

  get themes(): FormArray<FormGroupTheme> {
    return this.formGroup.controls.themes;
  }

  constructor(private formGroupDirective: FormGroupDirective) {}

  ngOnInit(): void {
    this.formGroup = this.formGroupDirective.form.controls
      .reorderablePanel as FormOverall;

    this.themes.controls.forEach(() => {
      this.collapsedStates.push(false);
    });
  }

  addTheme(): void {
    this.themes.push(
      new FormGroup({
        theme: new FormControl<string>('', Validators.required),
        questions: new FormArray<FormGroupQuestion>([]),
      })
    );

    this.collapsedStates.push(false);
  }

  addQuestion(themeIndex: number): void {
    const themeGroup = this.themes.at(themeIndex);
    const theme = themeGroup.controls.theme.value;

    themeGroup.controls.questions.push(
      new FormGroup({
        question_id: new FormControl<string>(
          this.generateUniqueId(),
          Validators.required
        ),
        question: new FormControl<string>('', Validators.required),
        theme: new FormControl<string>(theme, Validators.required),
        label: new FormControl<string>(''),
      })
    );
  }

  removeQuestion(themeIndex: number, questionIndex: number): void {
    const questions = this.getQuestions(themeIndex);
    questions.removeAt(questionIndex);
  }

  removeTheme(themeIndex: number): void {
    this.themes.removeAt(themeIndex);
    this.collapsedStates.splice(themeIndex, 1);
  }

  onThemeDrop(event: CdkDragDrop<FormGroup[]>): void {
    if (event.previousIndex !== event.currentIndex) {
      const movedTheme = this.themes.at(event.previousIndex);
      this.themes.removeAt(event.previousIndex);
      this.themes.insert(event.currentIndex, movedTheme);
    }
  }

  onQuestionDrop(themeIndex: number, event: CdkDragDrop<FormGroup[]>): void {
    if (event.previousIndex !== event.currentIndex) {
      const questions = this.getQuestions(themeIndex);
      const movedQuestion = questions.at(event.previousIndex);
      questions.removeAt(event.previousIndex);
      questions.insert(event.currentIndex, movedQuestion);
    }
  }

  toggleCollapse(index: number): void {
    this.collapsedStates[index] = !this.collapsedStates[index];
  }

  private generateUniqueId() {
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
      (
        +c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
      ).toString(16)
    );
  }

  private getQuestions(themeIndex: number): FormArray<FormGroupQuestion> {
    return this.themes.at(themeIndex).controls.questions;
  }
}
