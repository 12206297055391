import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IDataCaptureSegment } from '../../models/data-capture.models';

@Component({
  selector: 'app-skeleton-data-capture',
  templateUrl: './skeleton-data-capture.component.html',
  styleUrls: ['./skeleton-data-capture.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonDataCaptureComponent {
  @Input() sheetName: string;
  @Input() segments: IDataCaptureSegment[] = [];

  rows = Array(4).fill(null);
}
