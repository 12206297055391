import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CognitoAuthService } from '@techspert-io/auth';
import { OpportunityNavigationService } from '@techspert-io/navigation';
import { IOpportunity } from '@techspert-io/opportunities';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Observable, Subject, combineLatest } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { OmnisearchService } from '../../features/omnisearch/services/omnisearch.service';
import {
  ExpertStoreService,
  IExpertPhases,
} from '../../shared/state/expert.service';
import { OpportunityStoreService } from '../../shared/state/opportunity.service';
import { AuthDialogInceptorService } from './services/auth-dialog-inceptor.service';

@Component({
  selector: 'app-opportunity',
  templateUrl: './opportunity.component.html',
  styleUrls: ['./opportunity.component.scss'],
})
export class OpportunityComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  private tableRendered$ = new Subject();

  newExpertCount: number;
  showDescription = true;
  searchTerm: string;
  selectedIndex = 0;
  showTrackingTable: boolean;
  isFileDownloadAuthorised = false;
  isClientUser = false;
  showOpp = true;
  forceLogin = false;

  vmExperts$: Observable<{
    experts: IExpertPhases;
    expertApprovalRequired: boolean;
    loading: boolean;
  }> = EMPTY;

  expertLoadingIds$ = this.expertStore.expertLoadingIds$;

  opportunity$: Observable<IOpportunity> = EMPTY;
  isExpertApprovalRequired$: Observable<{ value: boolean }> = EMPTY;
  showEchoAsk: boolean;
  showDataCapture: boolean;
  opportunitySetUp$ = new Subject<boolean>();

  isProgrammaticChange = false;

  @ViewChild('tab-group') set table(_: HTMLElement) {
    this.tableRendered$.next(true);
  }

  constructor(
    private opportunityStore: OpportunityStoreService,
    private expertStore: ExpertStoreService,
    private activatedRoute: ActivatedRoute,
    private cognitoAuthService: CognitoAuthService,
    private router: Router,
    private titleService: Title,
    private omnisearchService: OmnisearchService,
    private opportunityNavigationState: OpportunityNavigationService,
    private gaService: GoogleAnalyticsService,
    private toastService: ToastrService,
    private authDialogInceptorService: AuthDialogInceptorService
  ) {}

  ngOnInit(): void {
    this.omnisearchService.omnisearchFocused$
      .pipe(tap((focused) => (this.showOpp = !focused)))
      .subscribe();

    const opp$ = this.activatedRoute.paramMap.pipe(
      map((params) => params.get('id')),
      distinctUntilChanged(),
      tap(() => this.opportunitySetUp$.next(false)),
      tap(() => this.toastService.clear()),
      tap(() => this.omnisearchService.setFocused(false)),
      switchMap((oppId) => this.opportunityStore.getOpportunity(oppId)),
      catchError((error) => {
        switch (error?.status) {
          case 401:
            this.forceLogin = true;
            break;
          case 403:
            this.router.navigate(['/not-authorised']);
            break;
          case 404:
          default:
            this.router.navigate(['not-found']);
        }

        return EMPTY;
      }),
      shareReplay(1)
    );

    this.opportunity$ = opp$.pipe(
      tap((opportunity) => this.setupOpportunity(opportunity))
    );

    this.isExpertApprovalRequired$ = opp$.pipe(
      switchMap((opp) =>
        this.cognitoAuthService.isClientFeatureEnabled(
          'expertApprovalRequired',
          opp.clientId
        )
      ),
      map((value) => ({ value }))
    );

    this.vmExperts$ = combineLatest([
      this.expertStore.experts$,
      this.isExpertApprovalRequired$,
      this.expertStore.loadingOppExperts$,
    ]).pipe(
      map(([experts, expertApprovalRequired, loading]) => ({
        experts,
        expertApprovalRequired: expertApprovalRequired.value,
        loading,
      }))
    );

    this.opportunityNavigationState.state$.subscribe();

    combineLatest([
      this.activatedRoute.queryParams,
      this.expertStore.experts$,
      this.opportunitySetUp$.pipe(filter(Boolean)),
    ])
      .pipe(
        tap(([queryParams, experts]) => this.setupRouting(queryParams, experts))
      )
      .subscribe();

    combineLatest([this.activatedRoute.queryParams, this.tableRendered$])
      .pipe(filter(([params]) => params.expertId))
      .subscribe(([params]) =>
        document.getElementById(params.expertId)?.scrollIntoView()
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }

  selectedTabChange(tabId: number): void {
    const mappedTab = this.mapTabId(tabId);

    this.router.navigate([], {
      queryParams: mappedTab ? { tab: mappedTab } : {},
      ...(this.isProgrammaticChange && { queryParamsHandling: 'merge' }),
    });

    this.isProgrammaticChange = false;
  }

  adjustTabIndex(tabId: number): void {
    this.selectedIndex = tabId;
  }

  toggleShowDescription(): void {
    this.showDescription = !this.showDescription;
  }

  openOmnisearchFromProjectProgressPage(): void {
    this.omnisearchService.setFocused(true);

    if (this.cognitoAuthService.loggedInUser) {
      this.gaService.gtag('event', 'click', {
        event_category: 'new_project_omnisearch_notification',
        dimension1: this.cognitoAuthService.loggedInUser.id,
      });
    }
  }

  navigateScreener(opportunityId: string): void {
    this.authDialogInceptorService.authIntercept(
      this.isFileDownloadAuthorised,
      () =>
        window.open(
          `${environment.screenerEndpoint}/${opportunityId}`,
          '_blank'
        )
    );

    this.gaService.gtag('event', 'click', {
      event_category: 'screener_comparison_navigation',
      opportunityId,
      ...(this.isFileDownloadAuthorised && {
        dimension1: this.cognitoAuthService.loggedInUser.id,
      }),
    });
  }

  onAuthTabClick(): void {
    this.authDialogInceptorService.authIntercept(this.isFileDownloadAuthorised);
  }

  private setupOpportunity(opportunity: IOpportunity): void {
    this.omnisearchService.setViewedProject(opportunity.opportunityName);

    this.showTrackingTable = this.isTrackable(opportunity);

    this.titleService.setTitle(opportunity.opportunityName);

    const userClient =
      this.cognitoAuthService.loggedInUser?.clients[opportunity.clientId];

    this.showEchoAsk =
      opportunity.startDate &&
      new Date(opportunity.startDate) >= new Date('2024-01-01');

    this.showDataCapture = opportunity.dataCapture;

    this.isClientUser =
      !!userClient || this.cognitoAuthService.loggedInUser?.userType === 'PM';

    this.isFileDownloadAuthorised = this.isClientUser;
    this.expertStore.setOpportunityId(opportunity.opportunityId);

    this.opportunitySetUp$.next(true);
  }

  private setupRouting(params: Params, experts?: IExpertPhases): void {
    if (params.expertId && !params.tab) {
      const tab = Object.entries(experts || {})
        .map(([k, v]) => v.find((e) => e.expertId === params.expertId) && k)
        .find(Boolean);

      const tabIndex = this.buildTabMap()[tab] || 0;

      if (tabIndex) {
        this.opportunityNavigationState.setNavigationState({
          [params.expertId]: true,
        });

        if (this.selectedIndex !== tabIndex) {
          this.isProgrammaticChange = true;
          this.adjustTabIndex(tabIndex);
        }
      }
    } else if (params.tab) {
      const tabIndex = this.buildTabMap()[params.tab] || 0;

      if (this.selectedIndex !== tabIndex) {
        this.isProgrammaticChange = true;
        this.adjustTabIndex(tabIndex);
      }
    }
  }

  private mapTabId(tabId: number): string | undefined {
    const tabMap = this.buildTabMap();

    const reverseTabMap = Object.entries(tabMap).reduce<Record<number, string>>(
      (acc, [k, v]) => ({ ...acc, [v]: k }),
      {}
    );

    return reverseTabMap[tabId] || undefined;
  }

  private buildTabMap() {
    const tabs = [
      'newExperts',
      'acceptedExperts',
      'completedConnections',
      'declinedExperts',
      ...(this.showDataCapture ? ['dataCapture'] : []),
      ...(this.showEchoAsk ? ['echoAsk'] : []),
    ];

    return tabs.reduce<Record<string, number>>(
      (acc, tab, i) => ({
        ...acc,
        [tab]: this.showTrackingTable ? i + 1 : i,
      }),
      {}
    );
  }

  private isTrackable(opp: IOpportunity): boolean {
    if (opp.opportunitySegments?.length) {
      return !opp.startDate || new Date(opp.startDate).getFullYear() > 2020;
    }
    return false;
  }
}
